import { Link } from '@remix-run/react'

export default function Logo() {
	return (
		<Link to="/" className="leading-snug group text-3xl">
			<span className="font-light transition group-hover:-translate-x-1">
				Schol
			</span>
			<span className="font-bold transition group-hover:translate-x-1">
				Books
			</span>
		</Link>
	)
}